html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

body {
  margin: 0;
  font-family: "Goth", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafbff;
}

button,
input {
  font-family: "Goth", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Goth";
  src: local("Goth"), url(./assets/Gogh-Regular.ttf) format("truetype");
}

:root {
  --main: #f71f5c;
  --readius-main: 10px;
  --font-small: 14px;
}