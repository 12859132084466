.block {
  border-radius: 30px;
  padding: 60px 20px;
  color: white;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.35) 0px 7px 29px 0px;
}

.main_block {
  border-radius: 30px;
  background-image: linear-gradient(0deg, #505861, #000000);
  padding: 60px 20px;
  color: white;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.35) 0px 7px 29px 0px;
  /* display: flex; */
}

.main_block_flex {
  display: flex;
}

.main_block img {
  width: 100%;
  max-width: 250px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .main_block_flex {
      flex-direction: column; 
  }
  .main_block_flex img {
    order: 1; 
    margin: 0 auto;
  }
  .main_block_flex div {
    order: 2; 
    text-align: center;
  }
  .card_block {
    text-align: center;
  }
}

.pointer {
  cursor: pointer;
}

h1 {
  font-size: 36px;
}

p {
  font-size: 18px;
}

section {
  padding: 30px 0;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.cards_two {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
}

.card_block {
  border: 1px solid white;
  padding: 20px;
  border-radius: 10px;
}

.card-white {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.35) 0px 7px 29px 0px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.section_header {
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.text_heading {
  font-size: 28px;
}

.bold {
  font-weight: 700;
  font-size: 16px;
  z-index: 1;
}

.card-white div {
  position: relative;
  z-index: 100;
}

.card_img {
  width: 80px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.line {
  width: 80px;
  border-top: 5px solid rgb(247, 31, 92);
  margin: 0 auto;
}

.color-2 {
  background-image: linear-gradient(0deg, #505861, #000000);
  /* background-image: linear-gradient(0deg, #262a2e, #000000); */
}

.color-1 {
  background-image: linear-gradient(0deg, #42C2FF, #000000);
}

.center {
  text-align: center;
}

input {
  padding: 15px;
  outline: none;
  width: 100%;
  border: none;
  margin: 10px 0;
  font-size: 16px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}

.btn-main {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid var(--main);
  color: var(--main);
}

.login_block {
  padding-top: 100px;
  align-items: center;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.payment_block {
  margin-top: 100px;
  text-align: center;
}

.policy_link {
  color: gray;
  text-align: center;
  margin-bottom: 20px;
  font-size: 12px;
}

.policy_link a {
  color: gray;
  text-decoration: none;
}

.policy_link_main {
  color: white;
  text-decoration: none;
}

#payment-form {
  width: -webkit-fill-available;
  margin: 0 auto;
}