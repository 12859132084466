.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  /* min-height: 100vh; */
}

.wrapper {
  padding: 20px 0;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: var(--readius-main);
  margin-bottom: 20px;
}

.level {
  background-color: white;
  padding: 20px;
  border-radius: var(--readius-main);
  margin-bottom: 20px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 20px;
  box-shadow: rgba(100, 100, 111, 0.35) 0px 7px 29px 0px;
}

.level_icon {
  color: green;
}

.level-done {
  background-color: white;
}

.level-active {
  background-color: white;
}

.level-undone {
  background-color: #e4e4e4;
  box-shadow: none;
}

h2 {
  font-size: 18px;
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

h3 {
  font-size: 16px;
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

.block {
  margin-bottom: 30px;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon-small {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.flex {
  display: flex;
  gap: 20px;
}

.button {
  border: none;
  border: 1px solid var(--main);
  background-color: white;
  color: var(--main);
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.hint {
  font-size: 12px;
  color: gray;
  text-align: center;
}

.text {
  white-space: pre-wrap;
}

.text p {
  font-size: 14px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.text ol,
ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: -10px;
}

.text li {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: -10px;
}

.text h3 {
  font-size: 16px;
  margin-block-end: 0em;
  margin-block-start: 1em;
  color: var(--main);
}

.link {
  color: #0081e6;
  cursor: pointer;
  font-weight: 500;
}

.task {
  margin-top: 10px;
  color: var(--main);
  font-size: 18px;
  /* font-weight: 500; */
  /* background-color: white;
  padding: 10px; */
}

.blank {
  height: 100px;
}

.textarea {
  padding: 10px;
  /* outline: none; */
  outline: 1px solid lightgray;
  width: 100%;
  border: none;
  margin: 10px 0;
  font-size: 14px;
  border-radius: 3px;
  overflow: hidden;
}

li {
  font-size: 16px;
}

.header {
  border-bottom: 1px solid lightgray;
  padding: 20px 0;
}

.justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  gap: 10px;
}

.exercise_question_block {
  padding: 10px 0;
}

.text_block {
  margin-bottom: 30px;
}

.text_heading {
  font-weight: 500;
}

textarea {
  resize: none;
  box-sizing: border-box;
}

.szh-accordion__item {
  margin-bottom: 10px;
}

.szh-accordion__item-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.step_buttons_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.step_buttons_block div {
  text-align: center;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: var(--readius-main);
  color: lightgray;
  font-size: var(--font-small);
  cursor: pointer;
}

.step_buttons_block div:hover {
  border-color: var(--main);
  color: var(--main);
}

.level_count {
  color: gray;
}

.menu_item:hover {
  cursor: pointer;
  color: var(--main);
}

.header_block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.points {
  font-size: 12px;
}
.footer {
  font-size: 12px;
}
